function rewriteSlug(slug) {
  if (slug.startsWith("mailto:")) {
    return slug
  }
  const defaultLanguage = "de/"
  let newSlug = slug
  // replaces /de/home with /de
  newSlug = newSlug.replace("home", "")
  // replaces /en/blog/first-post with /blog/first-post
  newSlug = newSlug.replace(defaultLanguage, "")
  newSlug = newSlug.replace(/^\/?([^/]+(?:\/[^/]+)*)\/?$/, "/$1/") || "/"
  return newSlug
}

// es5 export because of import into gatsby-node.js
module.exports = rewriteSlug
