import React from "react"
import { Link } from "gatsby"
import rewriteSlug from "../../utils/rewriteSlug"
import "twin.macro"

const Drawer = ({ handleClick, visible, settings, lang }) => {
  return (
    <nav
      className={
        visible
          ? `fixed flex items-center justify-center left-0 right-0 top-0 bg-white h-screen z-40 transition duration-100 ease-out opacity-100 transform translate-x-0 py-16 md:py-20 pointer-events-auto text-granit-900`
          : `fixed flex items-center justify-center left-0 right-0 top-0 bg-white z-40 h-screen transition-transform duration-500 ease-out opacity-0 transform translate-x-1/2 py-16 md:py-20 pointer-events-none`
      }
    >
      <ul tw="flex flex-col items-center space-y-5">
        {settings &&
          settings.main_navi.map((navitem, index) => (
            <li key={index}>
              {navitem.navitem_option === "textlink" && (
                <Link
                  to={rewriteSlug(navitem.link.cached_url)}
                  prefetch="true"
                  tw="navLink-animated font-heading uppercase tracking-widest block px-5 py-5"
                  onClick={handleClick}
                >
                  {navitem.name}
                </Link>
              )}
              {navitem.navitem_option === "button" && (
                <Link
                  to={rewriteSlug(navitem.link.cached_url)}
                  prefetch="true"
                  tw="button-animated mt-5 px-4 pt-4 pb-3 inline-flex items-center text-xs md:text-sm font-heading tracking-widest leading-none md:leading-relaxed uppercase focus:outline-none border border-current"
                  onClick={handleClick}
                >
                  {navitem.name}
                </Link>
              )}
            </li>
          ))}
        <li tw="flex pt-6 items-center">
          <Link
            to={"/"}
            prefetch="true"
            className={`navLink-animated font-heading uppercase lg:text-sm tracking-widest block p-5 leading-none ${
              lang !== "en" ? "opacity-60 pointer-events-none" : ""
            }`}
          >
            DE
          </Link>{" "}
          /
          <Link
            to={"/en/"}
            prefetch="true"
            className={`navLink-animated font-heading uppercase lg:text-sm tracking-widest block p-5 leading-none ${
              lang === "en" ? "opacity-60 pointer-events-none" : ""
            }`}
          >
            EN
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Drawer
