import React, { useState } from "react"

const InViewportContext = React.createContext()

const InViewportProvider = ({ children }) => {
  const [inViewport, setInViewport] = useState(false)
  const [dynamicNavbar, setDynamicNavbar] = useState(false)
  const initialState = {
    inViewport,
    dynamicNavbar,
    setInViewport,
    setDynamicNavbar,
  }

  return (
    <InViewportContext.Provider value={initialState}>
      {children}
    </InViewportContext.Provider>
  )
}

export { InViewportContext, InViewportProvider }
export default InViewportContext
