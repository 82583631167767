import React from "react"
import tw, { css } from "twin.macro"
import Navigation from "../components/navigation"
import { Global } from "@emotion/react"
import Footer from "../components/footer"
import { useStaticQuery, graphql } from "gatsby"
import useLocale from "../hooks/useLocale"

export default function Layout({ children, location, lang }) {
  const { settings } = useStaticQuery(graphql`
    query Settings {
      settings: allStoryblokEntry(
        filter: { field_component: { eq: "settings" } }
      ) {
        edges {
          node {
            name
            full_slug
            content
            lang
          }
        }
      }
    }
  `)

  let language = location.pathname.split("/")[1].replace("/", "")
  let activeLanguage = ["en"].includes(language) ? language : "default"
  const locale = useLocale(location)
  let correctSetting = settings.edges.filter(
    edge => edge.node.lang.indexOf(activeLanguage) > -1
  )
  let hasSetting =
    correctSetting && correctSetting.length ? correctSetting[0].node : {}
  let content =
    typeof hasSetting.content === "string"
      ? JSON.parse(hasSetting.content)
      : hasSetting.content
  let parsedSetting = Object.assign({}, content, { content: content })

  return (
    <>
      <Global
        styles={css`
          body {
            ${tw`antialiased font-body`}
            color: #272727;
            min-height: 100vh;
            display: flex;
            flex-direction: column;
          }
          html {
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            @media (min-width: 1152px) {
              font-size: calc(10.25px + 0.4vw);
              -webkit-marquee-increment: 0vw;
            }
            @media (min-width: 1800px) {
              font-size: 17.446px;
            }
          }
        `}
      />
      <div tw="flex flex-col min-h-screen">
        <Navigation settings={parsedSetting} lang={locale} />
        <main tw="flex flex-col flex-grow">{children}</main>
        <Footer settings={parsedSetting} lang={locale} />
      </div>
    </>
  )
}
