import React from "react"
import PropTypes from "prop-types"

const Icon = ({ svg, className }) => (
  <svg className={className} viewBox={svg.viewBox} title={svg.title} role="img">
    <use xlinkHref={svg.url} />
  </svg>
)

Icon.propTypes = {
  svg: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default Icon
