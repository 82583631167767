import React, { useState } from "react"
import "twin.macro"
import Bar from "./Bar"
import Logo from "./Logo"
import Burger from "./Burger"
import Menu from "./Menu"
import Drawer from "./Drawer"

const Nav = ({ settings, lang }) => {
  const [showDrawer, toggleDrawer] = useState(false)

  return (
    <Bar>
      <Logo visible={showDrawer} lang={lang} />
      <Burger
        tw="lg:hidden flex items-center text-white"
        handleClick={() => toggleDrawer(!showDrawer)}
        visible={showDrawer}
      />
      <Menu settings={settings} lang={lang} />
      <Drawer
        settings={settings}
        lang={lang}
        visible={showDrawer}
        handleClick={() => toggleDrawer(!showDrawer)}
      />
    </Bar>
  )
}
export default Nav
