/**
 * hook to extract the locale from the url
 * @param {object} location
 * @returns {string} locale
 */
const useLocale = location => {
  const { pathname } = location
  const language = pathname.split("/")[1].replace("/", "")
  const activeLanguage = ["en"].includes(language) ? language : "default"
  return activeLanguage
}

export default useLocale
