import React from "react"
import Container from "../container"
import "twin.macro"
import Icon from "../icon"
import LogoHefetol from "../../images/icons/logo-heftol.svg"
import LogoFacebook from "../../images/icons/logo-facebook.svg"
import LogoInstagram from "../../images/icons/logo-instagram.svg"
import LogoLinkedin from "../../images/icons/logo-linkedin.svg"
import rewriteSlug from "../../utils/rewriteSlug"
import { Link } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-react-intl"

const Footer = ({ settings, lang }) => {
  return (
    <footer>
      <Container verticalSpacingBase granit900 maxWidth>
        <div tw="flex flex-wrap md:-mx-5">
          <div tw="mb-10 lg:mb-0 md:px-5 w-full md:w-1/2 lg:w-4/12">
            <Link
              to={lang === "default" ? "/" : `/${lang}/`}
              title="Home"
              tw="text-warmGray-700"
            >
              <Icon svg={LogoHefetol} tw="mb-2 lg:mb-4 w-28 md:w-36 lg:w-40" />
              <span tw="sr-only">Home</span>
            </Link>
            <address tw="text-sm text-warmGray-100 leading-relaxed not-italic">
              Kurfürstendamm 102
              <br />
              10711 Berlin
              <br />
              Germany
            </address>
            <a
              tw="text-sm  text-warmGray-100 hover:text-white transition underline underlineOffset-small"
              href="https://goo.gl/maps/GkwMAsrx2w99UQYC9"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="Location" />
            </a>
          </div>
          <div tw="mb-10 lg:mb-0 lg:mt-6 md:px-5 w-full md:w-1/2 lg:w-3/12">
            <h3 tw="font-heading uppercase text-warmGray-700 mb-2">
              <FormattedMessage id="contact" />
            </h3>
            <ul tw="text-sm text-warmGray-100 leading-relaxed">
              <li>
                <a
                  tw="hover:text-white transition"
                  href="mailto:info@heftol.com"
                  rel="noopener noreferrer"
                >
                  info@heftol.com
                </a>
              </li>
              <li>
                <a
                  tw="hover:text-white transition"
                  href="tel:004930398200550"
                  rel="noopener noreferrer"
                >
                  T: +49 30 398 200 550
                </a>
              </li>
              <li>
                <a
                  tw="hover:text-white transition"
                  href="fax:004930398200559"
                  rel="noopener noreferrer"
                >
                  F: +49 30 398 200 559
                </a>
              </li>
            </ul>
          </div>
          <div tw="mb-10 lg:mb-0 lg:mt-6 md:px-5 w-full md:w-1/2 lg:w-3/12">
            <h3 tw="font-heading uppercase text-warmGray-700 mb-2">
              Office Berlin
            </h3>
            <p tw="text-sm text-warmGray-100 leading-relaxed">
              <FormattedMessage id="OfficeHours" /> <br />
              Mo - Fr: 9:00 - 18:00
            </p>
          </div>
          <div tw="md:px-5 w-full md:w-1/2 lg:w-2/12 lg:mt-5">
            <ul tw="text-warmGray-100 leading-loose text-sm">
              {settings &&
                settings.footer_navi.map((navitem, index) => (
                  <li key={index}>
                    <Link
                      to={rewriteSlug(navitem.link.cached_url)}
                      prefetch="true"
                      tw="hover:text-white transition underline underlineOffset-small"
                    >
                      {navitem.name}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </Container>
      <Container granit800 maxWidth>
        <div tw="flex justify-end text-granit-500 -mr-4">
          <a
            tw="text-warmGray-100 hover:text-white px-4 py-4"
            href="https://www.facebook.com/heftolgroup/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon svg={LogoFacebook} tw="h-5 w-5" />
            <span tw="sr-only">Facebook</span>
          </a>
          <a
            tw="text-warmGray-100 hover:text-white px-4 py-4"
            href="https://www.instagram.com/heftolgroup/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon svg={LogoInstagram} tw="h-5 w-5" />
            <span tw="sr-only">Instagram</span>
          </a>
          <a
            tw="text-warmGray-100 hover:text-white px-4 py-4"
            href="https://www.linkedin.com/company/heftol/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon svg={LogoLinkedin} tw="h-5 w-5" />
            <span tw="sr-only">Linkedin</span>
          </a>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
