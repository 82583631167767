import React from "react"
// import Icon from '../icon'
// import IconBurger from '../../images/icons/icon-burger.svg'
import "twin.macro"
import { Squash as Hamburger } from "hamburger-react"
import { motion } from "framer-motion"

const Burger = ({ handleClick, visible }) => {
  const opacityAnimation = {
    hide: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        ease: "easeOut",
        duration: 1,
        delay: 0.3,
      },
    },
  }

  return (
    <motion.button
      className={
        visible
          ? `ml-auto lg:hidden flex items-center -mr-4  relative z-50 h-12 w-12 focus:outline-none text-granit-900`
          : `ml-auto lg:hidden flex items-center -mr-4  relative z-50 h-12 w-12 focus:outline-none `
      }
      type="button"
      onClick={handleClick}
      initial="hide"
      whileInView="show"
      viewport={{ once: true }}
      variants={opacityAnimation}
    >
      <Hamburger size={20} hideOutline={false} label={"Menue"} duration={0} />
    </motion.button>
  )
}

export default Burger
