import React, { useContext } from "react"
import Container from "../container"
import tw from "twin.macro"
import InViewportContext from "../../contexts/InViewportContext"
import { motion } from "framer-motion"

const opacityAnimation = {
  hide: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      ease: "easeOut",
      duration: 1,
      delay: 0.3,
    },
  },
}

const Bar = ({ children }) => {
  const { inViewport, dynamicNavbar } = useContext(InViewportContext)
  const transparentStyles = tw`text-white bg-transparent`
  const whiteStyles = tw`bg-white bg-opacity-90`

  return (
    <motion.header
      tw="fixed w-full top-0 z-20 opacity-0"
      initial="hide"
      whileInView="show"
      viewport={{ once: true }}
      variants={opacityAnimation}
    >
      <Container>
        <div
          css={[
            dynamicNavbar && inViewport && transparentStyles,
            !dynamicNavbar && !inViewport && whiteStyles,
            !dynamicNavbar && inViewport && whiteStyles,
          ]}
        >
          <div tw="max-w-screen-8xl mx-auto flex flex-wrap items-center md:flex-nowrap py-1 lg:py-3 px-4 sm:px-6 md:px-8 xl:px-20">
            {children}
          </div>
        </div>
      </Container>
    </motion.header>
  )
}

export default Bar
