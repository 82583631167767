import React from "react"
import { Link } from "gatsby"
import Icon from "../icon"
import LogoHefetol from "../../images/icons/logo-heftol.svg"
// import Icon from '../Icon'
import { motion } from "framer-motion"

const opacityAnimation = {
  hide: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      ease: "easeOut",
      duration: 1,
      delay: 0.3,
    },
  },
}

const Logo = ({ visible, lang }) => {
  return (
    <motion.div
      className={
        visible
          ? `w-36 md:w-40 lg:w-44 relative z-50 text-granit-900`
          : `w-36 md:w-40 lg:w-44 relative z-50`
      }
      initial="hide"
      whileInView="show"
      viewport={{ once: true }}
      variants={opacityAnimation}
    >
      <Link to={lang === "default" ? "/" : `/${lang}/`} title="Home">
        <Icon svg={LogoHefetol} tw="w-full h-auto" />
      </Link>
    </motion.div>
  )
}

export default Logo
