import React from "react"
import "twin.macro"
import { Link } from "gatsby"
import rewriteSlug from "../../utils/rewriteSlug"

const Menu = ({ settings, lang }) => {
  return (
    <nav tw="hidden lg:flex w-auto flex-grow items-center" role="navigation">
      <ul tw="flex flex-row items-center mx-0 mt-0 pt-0 ml-auto">
        {settings &&
          settings.main_navi.map((navitem, index) => (
            <li key={index}>
              {navitem.navitem_option === "textlink" && (
                <Link
                  to={rewriteSlug(navitem.link.cached_url)}
                  prefetch="true"
                  className="navLink-animated font-heading uppercase lg:text-sm tracking-widest block px-5 py-5 lg:py-1 lg:px-4 lg:mx-3"
                  activeClassName="active"
                >
                  {navitem.name}
                </Link>
              )}
              {navitem.navitem_option === "button" && (
                <Link
                  to={rewriteSlug(navitem.link.cached_url)}
                  prefetch="true"
                  tw="button-animated ml-7 px-4 pt-4 pb-3 md:pt-2 md:pb-1 inline-flex items-center text-xs md:text-sm font-heading tracking-widest leading-none md:leading-relaxed uppercase focus:outline-none border border-current"
                >
                  {navitem.name}
                </Link>
              )}
            </li>
          ))}
        <li tw="flex pl-10">
          <Link
            to={"/"}
            prefetch="true"
            className={`navLink-animated font-heading uppercase lg:text-sm tracking-widest block py-5 lg:py-1 lg:mx-1 ${
              lang !== "en" ? "opacity-60 pointer-events-none" : ""
            }`}
          >
            DE
          </Link>{" "}
          /
          <Link
            to={"/en/"}
            prefetch="true"
            className={`navLink-animated font-heading uppercase lg:text-sm tracking-widest block py-5 lg:py-1 lg:mx-1 ${
              lang === "en" ? "opacity-60 pointer-events-none" : ""
            }`}
          >
            EN
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Menu
