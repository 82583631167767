import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

const Fluid = ({
  children,
  warmGray100 = false,
  white = false,
  granit900 = false,
  granit800 = false,
  verticalSpacingXs = false,
  verticalSpacingSm = false,
  verticalSpacingSmBottom = false,
  verticalSpacingBase = false,
  verticalSpacingMd = false,
  verticalSpacingLg = false,
  verticalSpacingBaseBottom = false,
  verticalSpacingMdBottom = false,
  verticalSpacingLgBottom = false,
  verticalSpacingNavigationTop = false,
  verticalSpacingLgTop = false,
  maxWidth = false,
  maxWidthSm = false,
  maxWidthMd = false,
  overflowHidden = false,
  bgOpacity90 = false,
  textWhite = false,
  backgroundCard = false,
  horizontalSpacingLeft = false,
}) => {
  return (
    <div
      css={[
        tw`w-full`,
        white && tw`bg-white`,
        warmGray100 && tw`bg-warmGray-100 bg-opacity-30`,
        granit900 && tw`bg-granit-900`,
        granit800 && tw`bg-granit-800`,
        overflowHidden && tw`overflow-hidden`,
        bgOpacity90 && tw`bg-opacity-90`,
        textWhite && tw`text-white`,
        backgroundCard && tw`bg-map-europe bg-no-repeat bg-right`,
      ]}
    >
      <div
        css={[
          maxWidth &&
            tw`max-w-screen-8xl mx-auto px-4 sm:px-6 md:px-8 xl:px-20`,
          maxWidthSm && tw`max-w-screen-8xl mx-auto px-4 sm:px-6 md:px-0`,
          maxWidthMd && tw`max-w-screen-8xl md:mx-auto md:px-8 xl:px-20`,
          horizontalSpacingLeft && tw`pl-4 md:pl-8 xl:pl-20 overflow-hidden`,
          !maxWidth && tw`w-full`,
          verticalSpacingXs && tw`py-1 lg:py-2`,
          verticalSpacingSm && tw`py-12 md:py-14 lg:py-16`,
          verticalSpacingSmBottom && tw`pb-12 md:pb-14 lg:pb-16`,
          verticalSpacingBase && tw`py-14 md:py-20 lg:py-24`,
          verticalSpacingBaseBottom && tw`pb-16 md:pb-20 lg:pb-24`,
          verticalSpacingMd && tw`py-20 md:py-24 lg:py-28`,
          verticalSpacingMdBottom && tw`pb-20 md:pb-24 lg:pb-28`,
          verticalSpacingLg && tw`py-28 md:py-32 lg:py-52`,
          verticalSpacingLgBottom && tw`pb-28 md:pb-32 lg:pb-52`,
          verticalSpacingLgTop && tw`pt-28 md:pt-32 lg:pt-52`,
          verticalSpacingNavigationTop && tw`pt-16 lg:mt-3`,
        ]}
      >
        {children}
      </div>
    </div>
  )
}

Fluid.propTypes = {
  children: PropTypes.node.isRequired,
  warmGray100: PropTypes.bool,
}

export default Fluid
